import { useQuery } from "@tanstack/react-query";
import { QuoteAPI } from "../utils/QuoteAPI";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";

export const useBreeds = (underwriter?: PetUnderwriterType) => {
    let quoteApi: QuoteAPI | undefined;
    if (underwriter && !quoteApi) {
        quoteApi = new QuoteAPI(underwriter);
    }
    const breedQuery = useQuery({
        queryKey: [`breeds`, underwriter],
        queryFn: async () => {
            if (!underwriter || !quoteApi) return [];
            return quoteApi.getBreeds();
        }
    });

    const { data, isFetching, error } = breedQuery;
    // TO-DO add useMutation hook for stateCode value

    return { breeds: data, breedsLoading: isFetching, breedsError: error };
};
