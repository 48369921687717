import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCat, faChevronDown, faDog, faPaw, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Species } from "spot-types/entities/Species";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { PriceInfo } from "@/shared/components/PriceInfo";
import PetModal from "@/shared/components/PetModal";
import { Button } from "@/shared/components/ui/Button";
import PetDropdown from "./PetDropdown";
import { Badge } from "../../ui/Badge";
import { cn } from "@/shared/utils";
import InfoIcon from "@/shared/media/icons/policy/info.svg";
import { MultiPetSelectProps } from "../types";
import { StickyMenu } from "../StickyMenu";
import { useIntersectionObserver } from "@/shared/hooks/useIntersectionObserver";
import { useCoverageEditorContext } from "../../CoverageEditor";
import Strings from "@/shared/utils/Strings.constants";

const MultiPetSelectDropdown: React.FC<Omit<MultiPetSelectProps, "variant">> = ({
    activePet,
    policyPets,
    handleActivePetChange: outerHandleActivePetChange,
    isMultiPetLinked,
    priceInfoData,
    petModalConfig
}) => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const { appState } = useAppLayerContext();
    const { quoteQuery, breakpoint } = appState;
    const quote = quoteQuery?.data;
    const policies = quote?.policies;
    const editorConfig = useCoverageEditorContext();
    const { getModalInitialValues } = editorConfig;
    const defaultCoverageSettings = policies?.[0]?.coverageSettings;
    const { ref: addAPetRef, isIntersecting } = useIntersectionObserver();
    const isAddAPetInView = isIntersecting ?? true;

    const handleOpenAddModal = () => setIsAddModalOpen(true);
    const handleCloseAddModal = () => setIsAddModalOpen(false);

    const handleActivePetChange = (petId: string) => {
        outerHandleActivePetChange(petId);
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        handleOpenAddModal();
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            handleOpenAddModal();
        }
    };

    const addPetButton = (
        <Button
            variant="outline-secondary"
            startDecorator={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleOpenAddModal}
            onTouchStart={handleTouchStart}
            onKeyDown={handleKeyDown}
            className="h-7 whitespace-nowrap px-2 py-1 text-xs"
        >
            {Strings.ADD_PET_GET_DISCOUNT}
        </Button>
    );

    const isBadgeVisible = isMultiPetLinked && policyPets.length > 1;

    const triggerButton = isBadgeVisible ? (
        <button type="button">
            <Badge className="text-md cursor-pointer rounded-md bg-background-brand-b-secondary px-4 py-3 text-center text-base leading-5 text-content-brand-b">
                <div className="flex items-center space-x-2">
                    <FontAwesomeIcon icon={faPaw} />
                    <span>{policyPets.length} Pets</span>
                    <InfoIcon />
                </div>
            </Badge>
        </button>
    ) : (
        <Button
            type="button"
            variant="ghost"
            className="peer h-11 w-full grow appearance-none justify-between rounded-md bg-background-primary px-4 py-3 text-base text-content-primary shadow-faux-border placeholder:text-content-secondary placeholder:opacity-0 focus:outline-none focus:placeholder:opacity-100"
        >
            <div className="flex w-full items-center justify-between">
                <div className="flex min-w-0 grow items-center gap-2">
                    {activePet ? (
                        <>
                            <FontAwesomeIcon icon={activePet.species === Species.Dog ? faDog : faCat} size="lg" className="shrink-0" />
                            <span className="truncate">{activePet.name}</span>
                        </>
                    ) : (
                        "Select a pet"
                    )}
                </div>
                <FontAwesomeIcon icon={faChevronDown} className="ml-2 shrink-0" />
            </div>
        </Button>
    );

    const dropdownContainerRef = React.useRef<HTMLDivElement>(null);
    return (
        <>
            <StickyMenu isVisible={!isAddAPetInView} />
            <div ref={addAPetRef} className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                    <div ref={dropdownContainerRef} className={cn("flex items-center gap-4", isBadgeVisible ? "w-full" : "w-1/2")}>
                        <div className={cn("min-w-0", isBadgeVisible ? "" : "grow")}>
                            <PetDropdown
                                policyPets={policyPets}
                                handleActivePetChange={handleActivePetChange}
                                petModalConfig={petModalConfig}
                                triggerButton={triggerButton}
                                containerRef={dropdownContainerRef}
                                isMultiPetLinked={isMultiPetLinked}
                                showFooter
                                showEditButton
                            />
                        </div>
                        <div className={cn("hidden shrink-0", isBadgeVisible ? "sm:block" : "md:block")}>
                            <PetModal
                                initialValues={{ policies: [getModalInitialValues(defaultCoverageSettings)] }}
                                triggerButton={addPetButton}
                                isOpen={isAddModalOpen}
                                handleOpen={handleOpenAddModal}
                                onClose={handleCloseAddModal}
                                config={petModalConfig}
                                handleActivePetChange={handleActivePetChange}
                            />
                        </div>
                    </div>

                    <div className={cn("flex flex-col items-end", isBadgeVisible ? "shrink-0" : "w-1/2")}>
                        <PriceInfo
                            variant={isMultiPetLinked ? "all-pets" : "single-pet"}
                            petId={activePet?.id}
                            totalPrice={priceInfoData.roundedPrice}
                            discountsAmount={priceInfoData.discountsAmount}
                            policiesCount={policies?.length ?? 0}
                        />
                    </div>
                </div>
                <div className={cn("flex justify-center", isBadgeVisible ? "sm:hidden" : "md:hidden")}>
                    <PetModal
                        initialValues={{ policies: [getModalInitialValues(defaultCoverageSettings)] }}
                        triggerButton={addPetButton}
                        isOpen={isAddModalOpen}
                        handleOpen={handleOpenAddModal}
                        onClose={handleCloseAddModal}
                        config={petModalConfig}
                        handleActivePetChange={handleActivePetChange}
                    />
                </div>
            </div>
        </>
    );
};

export default MultiPetSelectDropdown;
