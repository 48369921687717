import React from "react";
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger, SheetClose } from "@/shared/components/ui/sheet";
import { X } from "lucide-react";
import { cn } from "@/shared/utils";

export interface SheetWrapperStyles {
    content?: string;
    header?: string;
    title?: string;
    description?: string;
    closeButton?: string;
}

interface SheetWrapperProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string | React.ReactNode;
    description?: string;
    children: React.ReactNode;
    side?: "top" | "bottom" | "left" | "right";
    trigger?: React.ReactNode;
    styles?: SheetWrapperStyles;
    showCloseButton?: boolean;
}

export const SheetWrapper: React.FC<SheetWrapperProps> = ({ isOpen, onClose, title, description, children, side = "right", trigger, showCloseButton = true, styles = {} }) => {
    return (
        <Sheet open={isOpen} onOpenChange={onClose}>
            {!!trigger && <SheetTrigger asChild>{trigger}</SheetTrigger>}
            <SheetContent side={side} className={cn(styles.content)}>
                <SheetHeader className={cn(styles.header)}>
                    <SheetTitle className={cn(styles.title)}>{title}</SheetTitle>
                    {description && <SheetDescription className={cn(styles.description)}>{description}</SheetDescription>}
                </SheetHeader>
                {children}
                {showCloseButton && (
                    <SheetClose
                        className={cn(
                            "ring-offset-background focus:ring-ring data-[state=open]:bg-secondary flex items-center justify-center gap-2 rounded-sm px-4 py-3 text-base font-medium text-content-primary transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none",
                            styles.closeButton
                        )}
                    >
                        <X className="size-4" />
                        <span className="">Close</span>
                    </SheetClose>
                )}
            </SheetContent>
        </Sheet>
    );
};

SheetWrapper.displayName = "SheetWrapper";
