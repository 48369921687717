import { useMemo } from "react";
import { useQueries } from "@tanstack/react-query";
import { QuoteAPI } from "../utils/QuoteAPI";
import { PetUnderwriterType, QuoteStatus } from "spot-types/entities/PetQuote";
import { Quote } from "../types/Quote.interface";
import { Coverage } from "spot-types/entities/Coverage";
import { PublicConfig } from "../PublicConfig";
import Strings from "../utils/Strings.constants";

export type PetPlan = { baseCoverage: Coverage; riders: Coverage[] };

export const usePetPlans = (quote: Quote | undefined, underwriter: PetUnderwriterType) => {
    const quoteApi = useMemo(() => new QuoteAPI(underwriter), [underwriter]);
    const { id: quoteId, policies = [], ratingZipcode } = quote || {};

    const disabledQuoteStatuses: QuoteStatus[] = ["finalized", "finalize-processing"];

    const queries = policies.map(policy => ({
        enabled: !!quote?.quoteStatus && !disabledQuoteStatuses.includes(quote.quoteStatus),
        queryKey: ["petPlan", quoteId, ratingZipcode, policy.id, policy.species, policy.breedID, policy.gender, policy.age],
        queryFn: async () => {
            if (!quoteId || !policy.id || !ratingZipcode) {
                throw new Error("Missing required parameters");
            }

            if (quote?.quoteStatus === "finalized") {
                throw new Error("Quote is already finalized");
            }

            try {
                const plans = (await quoteApi.getPlans(quoteId, policy.id)) ?? [];

                if (underwriter === "ptz-us") {
                    return plans
                        .map(p => {
                            if (p.baseCoverage.details?.annualLimit?.value === PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE) {
                                return {
                                    ...p,
                                    baseCoverage: {
                                        ...p.baseCoverage,
                                        details: {
                                            ...p.baseCoverage.details,
                                            annualLimit: {
                                                symbol: Strings.UNLIMITED_ANNUAL_LIMIT_LABEL,
                                                value: PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE
                                            }
                                        }
                                    }
                                };
                            }
                            return p;
                        })
                        .filter(p => {
                            const annualLimitValue = p.baseCoverage.details?.annualLimit?.value;
                            return typeof annualLimitValue === "number" && (annualLimitValue <= 10000 || annualLimitValue === 0);
                        })
                        .filter(p => {
                            const deductible = p.baseCoverage.details?.deductible?.value;
                            return typeof deductible === "number" && deductible !== 300;
                        });
                }

                return plans;
            } catch (err) {
                console.error("Error fetching pet plans:", err);
                throw err;
            }
        }
    }));

    const queryResults = useQueries({
        queries,
        combine: results => {
            const petPlans = results.reduce(
                (acc, result, index) => {
                    const policy = policies[index];
                    if (policy && policy.id && result.data) {
                        acc[policy.id] = result.data;
                    }
                    return acc;
                },
                {} as Record<string, PetPlan[]>
            );

            return {
                petPlans,
                isPetPlansUpdating: results.some(result => result.isFetching || result.isLoading),
                isError: results.some(result => result.isError)
            };
        }
    });

    return queryResults;
};
