import React, { createContext, useContext, ReactNode } from "react";
import MultiPetSelectCard from "./MultiPetSelectCard";
import MultiPetSelectDropdown from "./MultiPetSelectDropdown";
import MultiPetSelectHybrid from "./MultiPetSelectHybrid";
import { MultiPetSelectProps } from "./types";

export type MultiPetSelectContextType = Omit<MultiPetSelectProps, "variant">;

const MultiPetSelectContext = createContext<MultiPetSelectContextType | undefined>(undefined);

export const useMultiPetSelectContext = () => {
    const context = useContext(MultiPetSelectContext);
    if (context === undefined) {
        throw new Error("useMultiPetSelectContext must be used within a MultiPetSelectProvider");
    }
    return context;
};

interface MultiPetSelectProviderProps {
    children: ReactNode;
    value: MultiPetSelectContextType;
}

const MultiPetSelectProvider: React.FC<MultiPetSelectProviderProps> = ({ children, value }) => {
    return <MultiPetSelectContext.Provider value={value}>{children}</MultiPetSelectContext.Provider>;
};

export const MultiPetSelect: React.FC<MultiPetSelectProps> = ({ variant, ...props }) => {
    const SelectedComponent = (() => {
        switch (variant) {
            case "card":
                return MultiPetSelectCard;
            case "dropdown":
                return MultiPetSelectDropdown;
            case "hybrid":
                return MultiPetSelectHybrid;
            default:
                return null;
        }
    })();

    if (!SelectedComponent) return null;

    return (
        <MultiPetSelectProvider value={props}>
            <SelectedComponent {...props} />
        </MultiPetSelectProvider>
    );
};

export default MultiPetSelect;
