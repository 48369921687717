import { useEffect } from "react";

type OverflowDimension = "width" | "height";

const useIsOverflowing = (ref: React.RefObject<HTMLElement>, callback: (isOverflowing: boolean) => void, dimension: OverflowDimension = "width") => {
    useEffect(() => {
        const element = ref.current;

        if (!element) return;

        const observerCallback = () => {
            let isOverflowing;
            if (dimension === "width") {
                isOverflowing = element.scrollWidth > element.clientWidth;
            } else {
                isOverflowing = element.scrollHeight > element.clientHeight;
            }

            callback(isOverflowing);
        };

        const resizeObserver = new ResizeObserver(observerCallback);
        resizeObserver.observe(element);

        // Initial check
        observerCallback();

        return () => resizeObserver.unobserve(element);
    }, [ref, callback, dimension]);
};

export default useIsOverflowing;
