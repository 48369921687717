import { useCallback, FunctionComponent } from "react";
import { Button } from "./ui/Button";
import { DialogProps } from "@/shared/types/Modals";
import { cn } from "../utils";

// media
import InfoIcon from "@/shared/media/icons/CircleInfoProRegular.svg";

interface FullCoverageDetailsButtonProps {
    modal: any;
    modalContent: React.FC<DialogProps>;
    samplePolicyUrl: string;
    buttonClass?: string;
    handleOpenModal?: (ModalComponent: FunctionComponent<any>, modalProps?: any) => void;
}

export default function FullCoverageDetailsButton(props: FullCoverageDetailsButtonProps) {
    const { modal, buttonClass, modalContent, samplePolicyUrl } = props;

    const handleOpenModal = useCallback(
        (ModalComponent: FunctionComponent<any>, modalProps?: any) => {
            modal.openModal(ModalComponent, modalProps || {});
        },
        [modal]
    );

    return (
        <>
            <Button
                onClick={() => handleOpenModal(modalContent as React.FC<DialogProps>, { samplePolicyUrl })}
                className={cn("text-xs text-content-secondary", buttonClass)}
                variant="ghost"
                startDecorator={<InfoIcon className="h-4" />}
                size="sm"
            >
                Full Coverage Details
            </Button>
        </>
    );
}
