import React, { useEffect, useRef } from "react";
import { UIUtils } from "@/shared/utils/UIUtils";
import { Switch, Label, Badge } from "@/shared/components/ui";
import LoaderWithText from "@/shared/components/LoaderWithText";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import Strings from "@/shared/utils/Strings.constants";

interface PriceInfoProps {
    totalPrice: number;
    policiesCount: number;
    discountsAmount: number;
    variant?: "floating" | "inline" | "single-pet" | "all-pets" | "multi-pet" | "single-pet-card" | "all-pets-card" | "single-pet-v2" | "all-pets-v2";
    petId?: string; // this is current pet ID
}

export const PriceInfo = (props: PriceInfoProps) => {
    const { totalPrice, discountsAmount, policiesCount, variant = "floating", petId } = props;
    const { appState, updateAppState } = useAppLayerContext();
    const { getValues } = useFormParentContext();

    const { quoteQuery, updateQuote, isQuoteUpdating, isMultiPetLinked, calculatedPriceData } = appState;
    const { data: quote } = quoteQuery || {};
    const calculatedFrequency = appState?.billingFrequency;
    const currentFrequency = quote?.billingInfo?.frequency;
    const frequencyToDisplay = calculatedFrequency ?? currentFrequency;
    const isAnnualBilling = frequencyToDisplay === "yearly";

    const getPriceData = () => {
        if (isMultiPetLinked || variant === "all-pets" || variant === "all-pets-card" || variant === "floating" || variant === "inline") {
            return calculatedPriceData;
        } else if (petId && calculatedPriceData?.singlePetPriceData) {
            return calculatedPriceData.singlePetPriceData[petId];
        }
        return null;
    };

    const priceData = getPriceData();
    const priceToDisplay = priceData?.finalPrice ?? totalPrice;
    const discountAmountToDisplay = priceData?.discountAmount ?? discountsAmount;
    const preDiscountPriceToDisplay = priceData?.preDiscountPrice ?? priceToDisplay + discountAmountToDisplay;

    const billingFreqText = isAnnualBilling ? "yr" : "mo";
    const currentTransactionFee = quote?.transactionFee?.value;
    const hasTransactionFee = !!currentTransactionFee && currentTransactionFee > 0;
    const billingFreqSavings = hasTransactionFee ? `Save $${currentTransactionFee * 12}/yr` : null;

    const formattedTotalPrice = UIUtils.formatNumber(priceToDisplay ?? 0, 2);
    const formattedTotalBeforeDiscount = UIUtils.formatNumber(preDiscountPriceToDisplay, 2);

    const handleFrequencyChange = async () => {
        const currentValues = getValues();
        const newValue = isAnnualBilling ? "monthly" : "yearly";
        updateAppState({ billingFrequency: newValue });
        try {
            const updatedQuote = await updateQuote?.mutateAsync({ ...currentValues, billingInfo: { frequency: isAnnualBilling ? "monthly" : "yearly" } });
            if (updatedQuote?.billingInfo?.frequency !== newValue) {
                updateAppState({ billingFrequency: undefined });
            }
        } catch (error) {
            updateAppState({ billingFrequency: undefined });
        }
        updateAppState({ isAnnualBilling: !isAnnualBilling });
    };

    const prevTotalPriceRef = useRef<number | undefined>(totalPrice);

    useEffect(() => {
        if (!isQuoteUpdating && calculatedPriceData !== undefined) {
            const hasTotalPriceChanged = prevTotalPriceRef.current !== totalPrice;

            if (hasTotalPriceChanged) {
                updateAppState({ calculatedPriceData: undefined });
            }
        }

        prevTotalPriceRef.current = totalPrice;
    }, [calculatedPriceData, isQuoteUpdating, totalPrice, updateAppState]);

    const renderFloatingVariant = () => (
        <div className="flex h-full flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
                <Switch
                    id="billing-frequency"
                    checked={isAnnualBilling}
                    onCheckedChange={handleFrequencyChange}
                    disabled={isQuoteUpdating || !!calculatedPriceData}
                    data-testid="billing-frequency-switch"
                    aria-labelledby="billing-frequency-label"
                />
                <Label id="billing-frequency-label" htmlFor="billing-frequency" className="mb-0 mr-8 bg-background-transparent text-sm text-content-primary">
                    {Strings.ANNUAL_BILLING_LABEL} {!!billingFreqSavings && <span className="block text-xs text-content-success">{billingFreqSavings}</span>}
                </Label>
            </div>
            {isQuoteUpdating && !calculatedPriceData ? (
                <LoaderWithText spinnerSize="sm" text={Strings.CALCULATING} className="gap-1 text-sm" />
            ) : (
                <div className="flex flex-col">
                    <div className="flex flex-col items-end text-content-success sm:flex-row sm:items-center sm:gap-2">
                        {discountAmountToDisplay > 0 && (
                            <div className="text-xs leading-5 text-content-secondary line-through">
                                ${formattedTotalBeforeDiscount}/{billingFreqText}
                            </div>
                        )}
                        <p className="text-left font-medium leading-5 text-content-primary">
                            <span data-testid="price-widget-floating-total-price" data-value={formattedTotalPrice}>
                                ${formattedTotalPrice}/{billingFreqText}
                            </span>
                        </p>
                    </div>
                    {discountAmountToDisplay > 0 && (
                        <div className="text-xs sm:place-self-end">
                            <Badge variant="secondary">Discount(s) Applied</Badge>
                        </div>
                    )}
                </div>
            )}
        </div>
    );

    const renderInlineVariant = () => (
        <div className="flex h-full flex-row items-center justify-between">
            {isQuoteUpdating && !calculatedPriceData ? (
                <LoaderWithText spinnerSize="sm" text={Strings.CALCULATING} className="gap-1 text-sm" />
            ) : (
                <div className="flex flex-col">
                    <div className="flex flex-col items-end text-content-success sm:flex-row sm:items-center sm:gap-2">
                        <p className="text-left font-medium leading-5 text-content-primary">
                            <span>{`(${policiesCount} ${policiesCount === 1 ? "Pet" : "Pets"}) `}</span>
                            <span data-testid="price-widget-inline-total-price" data-value={formattedTotalPrice}>
                                ${formattedTotalPrice}/{billingFreqText}
                            </span>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );

    const renderSinglePetVariant = () => {
        return (
            <div className="flex h-full flex-row justify-end">
                {isQuoteUpdating && !priceData ? (
                    <LoaderWithText spinnerSize="sm" text={Strings.CALCULATING} className="gap-1 text-sm" />
                ) : (
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center justify-end gap-2 text-content-success">
                            {discountAmountToDisplay > 0 && (
                                <div className="text-sm font-medium leading-4 text-content-secondary line-through">
                                    <span>${formattedTotalBeforeDiscount}</span>
                                </div>
                            )}
                            <p className="text-left font-bold leading-6 text-content-primary">
                                <span data-value={formattedTotalPrice}>
                                    ${formattedTotalPrice}/{billingFreqText}
                                </span>
                            </p>
                        </div>
                        {discountAmountToDisplay > 0 && (
                            <div className="order-2 flex items-center justify-end text-xs">
                                <Badge variant="secondary" className="h-5">
                                    Discount(s) Applied
                                </Badge>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderSinglePetV2Variant = () => {
        return (
            <div className="flex h-full flex-row justify-end pt-1">
                {isQuoteUpdating && !priceData ? (
                    <LoaderWithText spinnerSize="sm" text={Strings.CALCULATING} className="gap-1 text-sm" />
                ) : (
                    <div className="flex flex-col gap-1 md:flex-row md:gap-2">
                        <div className="order-1 flex flex-row items-center gap-2 text-content-success md:order-2">
                            {discountAmountToDisplay > 0 && (
                                <div className="text-sm font-medium leading-4 text-content-secondary line-through">
                                    <span>${formattedTotalBeforeDiscount}</span>
                                </div>
                            )}
                            <p className="text-left font-bold leading-6 text-content-primary">
                                <span data-value={formattedTotalPrice}>
                                    ${formattedTotalPrice}/{billingFreqText}
                                </span>
                            </p>
                        </div>
                        {discountAmountToDisplay > 0 && (
                            <div className="order-2 flex items-center justify-end text-xs md:order-1">
                                <Badge variant="secondary" className="h-5">
                                    Discount(s) Applied
                                </Badge>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderAllPetsVariant = () => {
        return (
            <div className="flex h-full flex-row justify-end">
                {isQuoteUpdating && !calculatedPriceData ? (
                    <LoaderWithText spinnerSize="sm" text={Strings.CALCULATING} className="gap-1 text-sm" />
                ) : (
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center justify-end gap-2 text-content-success">
                            {discountAmountToDisplay > 0 && (
                                <div className="text-sm font-medium leading-4 text-content-secondary line-through">
                                    <span>${formattedTotalBeforeDiscount}</span>
                                </div>
                            )}
                            <p className="text-left font-bold leading-6 text-content-primary">
                                <span data-value={formattedTotalPrice}>
                                    ${formattedTotalPrice}/{billingFreqText}
                                </span>
                            </p>
                        </div>
                        {discountAmountToDisplay > 0 && (
                            <div className="order-2 flex items-center justify-end text-xs">
                                <Badge variant="secondary" className="h-5">
                                    Discount(s) Applied
                                </Badge>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderAllPetsV2Variant = () => {
        return (
            <div className="flex h-full flex-row justify-end pt-1">
                {isQuoteUpdating && !calculatedPriceData ? (
                    <LoaderWithText spinnerSize="sm" text={Strings.CALCULATING} className="gap-1 text-sm" />
                ) : (
                    <div className="flex flex-col gap-1 md:flex-row md:gap-2">
                        <div className="order-1 flex flex-row items-center gap-2 text-content-success md:order-2">
                            {discountAmountToDisplay > 0 && (
                                <div className="text-sm font-medium leading-4 text-content-secondary line-through">
                                    <span>${formattedTotalBeforeDiscount}</span>
                                </div>
                            )}
                            <p className="text-left font-bold leading-6 text-content-primary">
                                <span data-value={formattedTotalPrice}>
                                    ${formattedTotalPrice}/{billingFreqText}
                                </span>
                            </p>
                        </div>
                        {discountAmountToDisplay > 0 && (
                            <div className="order-2 flex items-center justify-end text-xs md:order-1">
                                <Badge variant="secondary" className="h-5">
                                    Discount(s) Applied
                                </Badge>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderMultiPetVariant = () => {
        return (
            <div className="flex h-full flex-row justify-end pt-1">
                {isQuoteUpdating && !calculatedPriceData ? (
                    <LoaderWithText spinnerSize="sm" text={Strings.CALCULATING} className="gap-1 text-sm" />
                ) : (
                    <div className="flex flex-col gap-1">
                        <div className="order-1 flex flex-row items-center gap-2 text-content-success">
                            {discountAmountToDisplay > 0 && (
                                <div className="text-sm font-medium leading-4 text-content-secondary line-through">
                                    <span>${formattedTotalBeforeDiscount}</span>
                                </div>
                            )}
                            <p className="text-left font-bold leading-6 text-content-primary">
                                <span data-value={formattedTotalPrice}>
                                    ${formattedTotalPrice}/{billingFreqText}
                                </span>
                            </p>
                        </div>
                        {discountAmountToDisplay > 0 && (
                            <div className="order-2 flex items-center justify-end text-xs">
                                <Badge variant="secondary" className="h-5">
                                    Discount(s) Applied
                                </Badge>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const renderSinglePetCardVariant = () => {
        const hasDiscount = discountAmountToDisplay > 0;

        return (
            <>
                {hasDiscount && preDiscountPriceToDisplay !== undefined && (
                    <div className="flex justify-center">
                        <span className="select-none font-medium text-content-secondary line-through">
                            ${formattedTotalBeforeDiscount}/{billingFreqText}
                        </span>
                    </div>
                )}
                {priceToDisplay !== undefined && (
                    <div className="flex select-none items-center justify-center">
                        <span className="text-3xl font-bold">${formattedTotalPrice}</span>
                        <span className="text-xl font-medium">/{billingFreqText}</span>
                    </div>
                )}
                {hasDiscount && (
                    <div className="mt-1 flex select-none justify-center">
                        <Badge variant="secondary" className="h-5">
                            Discount(s) Applied
                        </Badge>
                    </div>
                )}
            </>
        );
    };

    const renderAllPetsCardVariant = () => {
        const hasDiscount = discountAmountToDisplay > 0;

        return (
            <>
                {hasDiscount && preDiscountPriceToDisplay !== undefined && (
                    <div className="flex justify-center">
                        <span className="select-none font-medium text-content-secondary line-through">
                            ${formattedTotalBeforeDiscount}/{billingFreqText}
                        </span>
                    </div>
                )}
                {priceToDisplay !== undefined && (
                    <div className="flex select-none items-center justify-center">
                        <span className="text-3xl font-bold">${formattedTotalPrice}</span>
                        <span className="text-xl font-medium">/{billingFreqText}</span>
                    </div>
                )}
                {hasDiscount && (
                    <div className="mt-1 flex select-none justify-center">
                        <Badge variant="secondary" className="h-5">
                            Discount(s) Applied
                        </Badge>
                    </div>
                )}
            </>
        );
    };

    if (priceToDisplay > 0 || (isQuoteUpdating && calculatedPriceData !== undefined)) {
        let content;
        switch (variant) {
            case "floating":
                content = renderFloatingVariant();
                break;
            case "inline":
                content = renderInlineVariant();
                break;
            case "single-pet":
                content = renderSinglePetVariant();
                break;
            case "all-pets":
                content = renderAllPetsVariant();
                break;
            case "multi-pet":
                content = renderMultiPetVariant();
                break;
            case "single-pet-card":
                content = renderSinglePetCardVariant();
                break;
            case "all-pets-card":
                content = renderAllPetsCardVariant();
                break;
            case "single-pet-v2":
                content = renderSinglePetV2Variant();
                break;
            case "all-pets-v2":
                content = renderAllPetsV2Variant();
                break;
            default:
                content = null;
                break;
        }

        if (variant === "floating") {
            return (
                <div className="fixed inset-x-0 bottom-0 z-50 sm:bottom-[25px] sm:left-auto sm:right-[25px] sm:min-w-[325px]">
                    <div className="h-20 border-stroke-primary bg-background-primary px-3 py-2 sm:h-16 sm:min-w-[400px] sm:rounded-full sm:border sm:px-5 sm:shadow-lg">
                        {content}
                    </div>
                </div>
            );
        }

        const noPaddingVariant = ["single-pet", "all-pets", "multi-pet", "single-pet-card", "all-pets-card", "single-pet-v2", "all-pets-v2"];
        if (noPaddingVariant.includes(variant)) {
            return <>{content}</>;
        }

        return <div className="px-4 py-3">{content}</div>;
    }

    return null;
};
