import { Button } from "./ui/Button";
import { ReimbursementTermsModal } from "@/shared/components/ReimbursementTermsModal";
import { CoverageModalTerms } from "@/shared/components/CoverageEditor";
import { useModal } from "../contexts/ModalProvider";

// media
import InfoIcon from "@/shared/media/icons/CircleInfoProRegular.svg";

interface ReimbursementTermsButtonProps {
    termsInModal: CoverageModalTerms[];
}

export default function ReimbursementTerms(props: ReimbursementTermsButtonProps) {
    const { termsInModal } = props;
    const { showModal } = useModal();

    return (
        <div className="flex w-full flex-row justify-center text-xs text-content-secondary sm:mb-0 sm:ml-auto">
            <Button
                onClick={() => showModal("reimbursement-terms-modal", <ReimbursementTermsModal termsInModal={termsInModal} />)}
                className="text-xs"
                startDecorator={<InfoIcon className="h-4" />}
                variant="ghost"
                size="sm"
            >
                What do these terms mean?
            </Button>
        </div>
    );
}
