import { Option } from "../types/Quote.interface";
import { Select } from "@/shared/components/ui/Select";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";
import { Label } from "@/shared/components/ui/label";
import kebabCase from "lodash/kebabCase";

interface DynamicOptionsFieldProps {
    id: string;
    options: Option[];
    initialValue?: string;
    onValueChange: (value: string) => void;
    label: string;
    toggleLimit?: number;
    constrainWidthToTrigger?: boolean;
}

/**
 * Renders a `Select`, `RadioButtonGroup`, or static content component depending on the number of `options`.
 */
function DynamicOptionsField(props: DynamicOptionsFieldProps) {
    const { id, options, label, initialValue, onValueChange, toggleLimit = 5, constrainWidthToTrigger = false } = props;
    const optionsLength = options?.length;
    const isToggleGroup = optionsLength > 1 && optionsLength <= toggleLimit;
    const labelId = `${kebabCase(label)}-label`;

    let content;

    switch (true) {
        case optionsLength === 1:
            content = (
                <div className="flex">
                    <span className="rounded-md bg-background-brand-b-secondary p-1 px-2 text-2xl font-semibold text-content-primary">{options[0]?.label}</span>
                </div>
            );
            break;
        case isToggleGroup:
            content = <RadioButtonGroup initialValue={initialValue} onValueChange={onValueChange} options={options} />;
            break;
        case !isToggleGroup:
            content = (
                <Select
                    value={initialValue}
                    onValueChange={onValueChange}
                    options={options}
                    className="h-16 flex-1"
                    constrainWidthToTrigger={constrainWidthToTrigger}
                    triggerProps={{
                        id: id,
                        "aria-labelledby": labelId
                    }}
                />
            );
            break;
        default:
            content = <></>;
    }

    return (
        <>
            {label && (
                <Label id={labelId} htmlFor={id} className="bg-background-transparent text-sm font-semibold text-content-primary">
                    {label}
                </Label>
            )}
            {content}
        </>
    );
}

export default DynamicOptionsField;
