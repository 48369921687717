import { useReducer } from "react";
import { OtherProps } from "../types/Form";

type Action = { type: "UPDATE"; stepId: string; newValues: OtherProps } | { type: "REMOVE"; stepId: string };

const reducer = (state: Record<string, OtherProps>, action: Action) => {
    switch (action.type) {
        case "UPDATE":
            return {
                ...state,
                [action.stepId]: {
                    ...state[action.stepId],
                    ...action.newValues
                }
            };
        case "REMOVE":
            const { [action.stepId]: _, ...rest } = state;
            return rest;
        default:
            return state;
    }
};

export const useOtherValuesMap = () => {
    const [otherValuesMap, dispatch] = useReducer(reducer, {});

    const updateOtherValues = (stepId: string, newValues: OtherProps) => {
        dispatch({ type: "UPDATE", stepId, newValues });
    };

    const removeOtherValues = (stepId: string) => {
        dispatch({ type: "REMOVE", stepId });
    };

    return {
        otherValuesMap,
        updateOtherValues,
        removeOtherValues
    };
};
