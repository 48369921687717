export const FreePetDisclaimer = () => {
    return (
        <div className="mx-auto flex max-w-[720px] flex-col gap-2 text-center text-sm">
            <h3 className="text-sm font-semibold text-content-primary">Free Pet Insurance Quote in 30 Seconds</h3>
            <p className="text-left text-content-secondary">
                Get your free quote on a pet insurance plan for your cat or dog. When adventure calls, ensure your pet always answers. Submit the form to get your{" "}
                <span className="font-semibold">free pet insurance quote.</span>
            </p>
        </div>
    );
};
