/** @type {import('tailwindcss').Config} */
module.exports = {
    darkMode: "selector",
    content: ["./pages/**/*.{ts,tsx}", "./components/**/*.{ts,tsx}", "./app/**/*.{ts,tsx}", "./src/**/*.{ts,tsx}", "./forms/**/*.{ts,tsx}"],
    future: {
        // Only apply hover styles on devices that support hover (ie, not on mobile devices)
        // https://github.com/tailwindlabs/tailwindcss/pull/8394
        hoverOnlyWhenSupported: true
    },
    theme: {
        container: {
            center: true,
            padding: "2rem",
            screens: {
                "2xl": "1400px"
            }
        },
        fontFamily: {
            sans: ["Poppins", "sans-serif"]
        },
        colors: {
            // Text colors. Ex: text-content-success
            content: {
                current: "currentColor",
                primary: "var(--text-primary)",
                "primary-invert": "var(--text-primary-invert)",
                secondary: "var(--text-secondary)",
                brand: "var(--text-brand)",
                "brand-b": "var(--text-brand-b)",
                "brand-secondary": "var(--text-brand-secondary)",
                success: "var(--text-success)",
                danger: "var(--text-danger)",
                inactive: "var(--gray-400)",
                // Third party
                facebook: "#1877F2",
                twitterX: "#0F1419",

                // Use primitive classes to bypass the token and directly reference the primitive.
                // *Note* Use only as escape hatch. Ex: text color should remain same in dark mode
                "primitive--white": "var(--white)"
            },
            // Border colors. Ex: border-stroke-secondary
            stroke: {
                transparent: "transparent",
                primary: "var(--border-primary)",
                secondary: "var(--border-secondary)",
                "secondary-invert": "var(--border-secondary-invert)",
                tertiary: "var(--border-tertiary)",
                brand: "var(--border-brand)",
                "brand-b": "var(--border-brand-b)",
                danger: "var(--border-danger)",

                // Use primitive classes to bypass the token and directly reference the primitive.
                // *Note* Use only as escape hatch. Ex: border color should remain same in dark mode
                "primitive--brand-c-700": "var(--brand-c-700)"
            },
            // Background colors. Ex: bg-background-primary
            background: {
                transparent: "transparent",
                overlay: "var(--brand-c-800-alpha)",
                primary: "var(--bg-primary)",
                "primary-invert": "var(--bg-primary-invert)",
                secondary: "var(--bg-secondary)",
                tertiary: "var(--bg-tertiary)",
                quaternary: "var(--bg-quaternary)",
                "brand-weakest": "var(--bg-brand-weakest)",
                "brand-primary": "var(--bg-brand-primary)",
                "brand-secondary": "var(--bg-brand-secondary)",
                "brand-tertiary": "var(--bg-brand-tertiary)",
                "brand-quaternary": "var(--bg-brand-quaternary)",
                "brand-b-primary": "var(--bg-brand-b-primary)",
                "brand-b-secondary": "var(--bg-brand-b-secondary)",
                "brand-b-tertiary": "var(--bg-brand-b-tertiary)",
                "brand-c-primary": "var(--brand-c-900)",
                success: "var(--bg-success)",
                "success-secondary": "var(--bg-success-secondary)",
                danger: "var(--bg-danger)",
                "danger-secondary": "var(--bg-danger-secondary)",

                // Use primitive classes to bypass the token and directly reference the primitive.
                // *Note* Use only as escape hatch. Ex: background color should remain same when in dark mode.
                "primitive--brand-c-800": "var(--brand-c-800)"
            }
        },
        spacing: {
            0: "0rem",
            1: "0.25rem",
            2: "0.5rem",
            3: "0.75rem",
            4: "1rem",
            5: "1.25rem",
            6: "1.5rem",
            7: "1.75rem",
            8: "2rem",
            9: "2.25rem",
            10: "2.5rem",
            11: "2.75rem",
            12: "3rem",
            14: "3.5rem",
            16: "4rem",
            20: "5rem",
            24: "6rem",
            28: "7rem",
            36: "9rem"
        },
        borderRadius: {
            none: "0rem",
            sm: "0.125rem",
            DEFAULT: "0.25rem",
            md: "0.375rem",
            lg: "0.5rem",
            xl: "0.75rem",
            "2xl": "1rem",
            "3xl": "1.5rem",
            full: "9999rem"
        },
        extend: {
            boxShadow: {
                "faux-border": "0 0 0 1px var(--gray-300)",
                "faux-border-danger": "0 0 0 1px var(--system-red-600)"
            },
            maxWidth: {
                content: "var(--content-width)"
            },
            keyframes: {
                "accordion-down": {
                    from: { height: 0 },
                    to: { height: "var(--radix-accordion-content-height)" }
                },
                "accordion-up": {
                    from: { height: "var(--radix-accordion-content-height)" },
                    to: { height: 0 }
                },
                spin: {
                    transform: "rotate(90deg)"
                }
            },
            animation: {
                "accordion-down": "accordion-down 0.2s ease-out",
                "accordion-up": "accordion-up 0.2s ease-out",
                spin: "spin .8s linear infinite"
            }
        }
    },
    plugins: [require("tailwindcss-animate")]
};
