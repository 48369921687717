import { Policy } from "@/shared/types/Quote.interface";
import { Label } from "@/shared/components/ui";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import Strings from "@/shared/utils/Strings.constants";

interface MultipetToggleProps {
    policies: Policy[] | undefined;
}

const MultipetToggle = (props: MultipetToggleProps) => {
    const { policies } = props;
    const { appState, updateAppState } = useAppLayerContext();

    const { isMultiPetLinked } = appState;
    const quoteHasMultiplePets = !!policies && policies.length > 1;
    return (
        <>
            {quoteHasMultiplePets && (
                <div className="flex items-center space-x-2">
                    <Checkbox
                        id="all-pets"
                        aria-labelledby="all-pets-label"
                        checked={isMultiPetLinked}
                        onCheckedChange={() => updateAppState({ isMultiPetLinked: !isMultiPetLinked })}
                    />
                    <Label id="all-pets-label" htmlFor="all-pets" className="mb-0 bg-background-transparent text-xs text-content-secondary">
                        {Strings.MULTIPET_LINKED_LABEL}
                    </Label>
                </div>
            )}
        </>
    );
};

export default MultipetToggle;
