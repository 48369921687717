import Strings from "@/shared/utils/Strings.constants";
import { UIUtils } from "@/shared/utils/UIUtils";
import { FC } from "react";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { UnderwriterConfigKey } from "../types/Quote.interface";

interface AdditionalPlansProps {
    underwriter: PetUnderwriterType;
}

const AdditionalPlans: FC<AdditionalPlansProps> = ({ underwriter }) => {
    const uwConfigKey = underwriter.replace("-", "_").toUpperCase() as UnderwriterConfigKey;
    return (
        <div className="text-left text-xs leading-5 text-content-secondary sm:text-center">
            Additional plan options available through Customer Service at{" "}
            <a className="underline" href={`tel:${Strings[uwConfigKey].PHONE_NUMBER}`} target="_blank">
                {UIUtils.formatPhone(Strings[uwConfigKey].PHONE_NUMBER, ".")}
            </a>
        </div>
    );
};

export default AdditionalPlans;
