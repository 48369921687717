import React from "react";
import LoaderWithText from "./LoaderWithText";
import { Dialog, DialogContent, DialogTitle, DialogDescription } from "./ui";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import Strings from "../utils/Strings.constants";

export function LoaderModal() {
    return (
        <Dialog open={true}>
            <DialogContent className="items-center justify-center">
                <VisuallyHidden asChild>
                    <DialogTitle>{Strings.WRAPPING_UP}</DialogTitle>
                </VisuallyHidden>
                <VisuallyHidden asChild>
                    <DialogDescription />
                </VisuallyHidden>
                <LoaderWithText id="finalizing-modal" text={Strings.WRAPPING_UP} />
            </DialogContent>
        </Dialog>
    );
}
