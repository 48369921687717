// packages
import React, { useCallback, useEffect, useState, useRef, useMemo, ReactNode, createContext, useContext } from "react";
import { useDeepCompareMemo } from "@react-hookz/web";
import { AnimatePresence, motion } from "framer-motion";

// components
import ReimbursementTerms from "./ReimbursementTerms";
import { RadioButtonGroup } from "@/shared/components/ui/RadioButtonGroup";
import { FormField } from "@/shared/components/FormField";
import { CoverageIconGrid } from "@/shared/components/CoverageIconGrid";
import { PreventiveCards } from "@/shared/components/PreventiveCards";
import { Heading } from "@/shared/components/ui";
import AdditionalPlans from "@/shared/components/AdditionalPlans";
import DynamicOptionsField from "@/shared/components/DynamicOptionsField";
import { MultiPetSelect } from "./MultiPetSelect";
import MultipetToggle from "./MultipetToggle";
import TakeoverProvision from "./TakeoverProvision";
import { CustomizationSlot } from "./CustomizationSlot";
import { PriceInfo } from "./PriceInfo";

// utils
import { CoverageUtils, CoverageValue, TransactionFeeType } from "@/shared/utils/CoverageUtils";
import { PresetCoverageLevel, PreventiveConfig } from "@/shared/utils/CoverageUtils";
import { BuilderUtils } from "@/shared/utils/BuilderUtils";
import { UIUtils } from "../utils/UIUtils";
import { MAX_PET_NAME_LABEL } from "../utils/constants";
import { AnalyticsUtils } from "../utils/AnalyticsUtils";
import { cn } from "../utils";
import ErrorUtils from "../utils/ErrorUtils";

// hooks
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { useStatsig } from "../hooks/useStatsig";

// types
import { CoverageSettings, Option, Quote, Pet, InitialPetValues } from "@/shared/types/Quote.interface";
import { FormStepProps } from "@/shared/types/Form";
import { PetUnderwriterType } from "spot-types/entities/PetQuote";
import { DialogProps } from "@/shared/types/Modals";
import { PetModalConfig } from "./PetModal";
import { PRIORITY_CODE_DEPRECATED } from "@/shared/types/SpotAPI";

// config
import { PublicConfig } from "../PublicConfig";

// media

import { UsQuoteFormSteps } from "@/quote-ptz-us/formConfigTypes";

export type CoverageModalTerms = "reimbursement" | "annualLimit" | "annualDeductible";

type CustomCoverageProps = {
    editorConfig: {
        formId: string;
        title?: string;
        coveragePresetData?: PresetCoverageLevel[];
        termsInModal?: CoverageModalTerms[];
        samplePolicyUrl?: string;
        modalContent: React.ComponentType<DialogProps>;
        preventiveConfig: PreventiveConfig;
        includeTransactionFee?: boolean;
        feeType?: TransactionFeeType;
        exclusions?: ReactNode;
        customizationSlot?: ReactNode;
        petModalConfig: PetModalConfig;
        getModalInitialValues: (defaultCoverageSettings?: CoverageSettings) => InitialPetValues;
        showTakeoverProvision?: boolean;
        priorityCode?: string;
    };
};

type CoverageEditorProps = FormStepProps<Quote> & CustomCoverageProps;

export function CoverageEditor(props: CoverageEditorProps) {
    const {
        formId,
        title = "Create your plan",
        coveragePresetData,
        termsInModal = ["annualDeductible", "annualLimit", "reimbursement"],
        samplePolicyUrl = "",
        modalContent,
        preventiveConfig,
        includeTransactionFee = true,
        feeType = "per-quote",
        exclusions,
        customizationSlot,
        petModalConfig,
        showTakeoverProvision = false,
        priorityCode
    } = props?.editorConfig || {};

    // local state
    const [customCoverageSettings, setCustomCoverageSettings] = useState<CoverageValue | null>(null);
    const [activePetIndex, setActivePetIndex] = useState(0);
    const coverageLevelIndex = CoverageUtils.DEFAULT_COVERAGE_INDEX;

    const initialRef = useRef(false);

    // form state
    const { setValue, getValues, watch } = useFormParentContext<Quote>();

    const quote = watch();
    const policies = watch("policies");

    // hooks
    const { appState, updateAppState } = useAppLayerContext();
    const { isMultiPetLinked, updateQuote, isQuoteUpdating, isApplyAllHidden, underwriter, breakpoint, isFormLoaderVisible, petPlansObject, mockAPIError } = appState;

    const { petPlans, isPetPlansUpdating } = petPlansObject || {};

    const queryParams = AnalyticsUtils.getQueryParams(quote?.extra?.queryParams);
    const builderUtils = new BuilderUtils(underwriter as PetUnderwriterType);

    // #statsig:start - coverage-step-multi-pet
    const { multiPetVariant } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "multiPetVariant", defaultValue: "card" }] as const,
        queryParams
    });
    // #statsig:end

    // Statsig - trust-pilot-vs-video-testimonials
    const { showVideoTestimonials } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "showVideoTestimonials", defaultValue: false }] as const,
        queryParams: AnalyticsUtils.getQueryParams(quote?.extra?.queryParams)
    });

    // Statsig:start: coverage-two-col
    const { coverageShowTwoColLayout } = useStatsig({
        layerId: PublicConfig.STATSIG_LAYER_ID_MAIN,
        layerParameters: [{ key: "coverageShowTwoColLayout", defaultValue: false }] as const
    });
    // Statsig:end

    useEffect(() => {
        if (showVideoTestimonials) {
            updateAppState({ showVideoTestimonials });
        }
    }, [showVideoTestimonials, updateAppState]);
    // Statsig:end

    // Initial data
    const allPets = useDeepCompareMemo(() => policies ?? [], [policies]);
    const activePet = useDeepCompareMemo(() => policies?.[activePetIndex], [policies, activePetIndex]);
    const isAnnualBilling = appState.isAnnualBilling;
    const activePetAllPlans = useMemo(() => petPlans?.[activePet?.id ?? ``] ?? [], [activePet?.id, petPlans]);
    const policyPets: Option[] = useMemo(() => allPets.map(pet => ({ value: pet.id ?? ``, label: pet.name ?? `` })), [allPets]);
    const priceInfoData = useMemo(
        () => CoverageUtils.getPriceInfoData({ value: quote, includeTransactionFee, feeType, petIdToShow: !isMultiPetLinked ? activePet?.id : undefined }),
        [activePet?.id, feeType, includeTransactionFee, isMultiPetLinked, quote]
    );

    // format pet plan API data for UI components
    const allCoverageOptions = useMemo(
        () => (!!activePetAllPlans.length ? CoverageUtils.formatCustomCoverageData(activePetAllPlans, isAnnualBilling) : null),
        [activePetAllPlans, isAnnualBilling]
    );

    const { coverageTypes, annualLimits, annualDeductibles, reimbursementRates, preventiveCareOptions } = allCoverageOptions || {};

    // find options that have multiple values for matching multipet coverage settings
    const coverageValuesToMatch = useMemo(() => {
        if (!!allCoverageOptions) {
            const valuesWithMultipleOptions = CoverageUtils.findKeysWithMultipleOptions(allCoverageOptions);
            const matchCriteriaKeys = CoverageUtils.convertKeysToMatchCriteria(valuesWithMultipleOptions);
            return matchCriteriaKeys;
        }
        return [];
    }, [allCoverageOptions]);

    const transactionFee = includeTransactionFee ? (getValues("transactionFee.value") ?? 0) : 0;
    const coveragePresets = useMemo(() => {
        if (!!activePet?.id && !!petPlans && !!coveragePresetData) {
            const activePetPlans = petPlans[activePet.id];
            if (!!activePetPlans) {
                return CoverageUtils.getPresetPlansFromApiData({ petPlans: activePetPlans, presetCoverageLevels: coveragePresetData });
            }
        }
        return [];
    }, [activePet?.id, petPlans, coveragePresetData]);

    const allPetsHaveSameCoverages = useMemo(() => {
        if (!!allPets.length) {
            const allCoverageSettings = allPets.map(pet => pet?.coverageSettings);
            if (!!allCoverageSettings.length) {
                return CoverageUtils.allPetCoverageSettingsMatch(allCoverageSettings, coverageValuesToMatch);
            }
        }
        return false;
    }, [allPets, coverageValuesToMatch]);

    const allPetsHaveFetchedPlans = useMemo(() => {
        if (!!petPlans && !isPetPlansUpdating) {
            const allPlanIds = Object.keys(petPlans);
            return allPets.every(pet => !!pet.id && allPlanIds.includes(pet.id));
        }
        return false;
    }, [allPets, isPetPlansUpdating, petPlans]);

    const activePetCoverage = useDeepCompareMemo(() => {
        const coverage = !!activePet && customCoverageSettings ? customCoverageSettings : coveragePresets[coverageLevelIndex];
        return coverage;
    }, [customCoverageSettings, coveragePresets, coverageLevelIndex, activePet]);

    const getWellnessLevel = (coverageSettings?: CoverageSettings) => {
        if (!coverageSettings) return "None";
        const wellnessCoverage = coverageSettings?.coverages?.find(c => c.type?.includes(`wellness`))?.type;
        return wellnessCoverage === "wellness-gold" ? "wellness-gold" : wellnessCoverage === "wellness-platinum" ? "wellness-platinum" : "None";
    };

    const [coverageLevel, setCoverageLevel] = useState(
        activePet?.coverageSettings?.coverages?.some(coverage => coverage.type === "illness") ? "accidentAndIllness" : "accidentOnly"
    );

    const [annualLimitValue, setAnnualLimitValue] = useState(activePet?.coverageSettings?.amounts?.annualLimit?.toString());
    const [annualDeductibleValue, setAnnualDeductibleValue] = useState(activePet?.coverageSettings?.amounts?.annualDeductible?.toString());
    const [reimbursementValue, setReimbursementValue] = useState(activePet?.coverageSettings?.amounts?.reimbursementRate?.toString());
    const [wellnessValue, setWellnessValue] = useState(getWellnessLevel(activePet?.coverageSettings));

    const updatePricing = useCallback(
        (newCoverageSettings: CoverageSettings) => {
            if (!!petPlans && !!activePet?.id) {
                const calculatedData = CoverageUtils.calculatePriceData({
                    coverageSettings: newCoverageSettings,
                    policies: allPets,
                    isMultiPetLinked,
                    activePetId: activePet.id,
                    petPlans: petPlans,
                    coverageValuesToMatch,
                    isAnnualBilling,
                    transactionFee,
                    feeType
                });

                // Restructure the data to fit our new app state structure
                const newCalculatedPriceData = {
                    preDiscountPrice: calculatedData.preDiscountPrice,
                    discountAmount: calculatedData.discountAmount,
                    finalPrice: calculatedData.finalPrice,
                    singlePetPriceData: calculatedData.perPetPriceData
                };

                // Update the app state with the new calculated price data

                updateAppState({
                    calculatedPriceData: newCalculatedPriceData
                });
            }
        },
        [activePet?.id, allPets, coverageValuesToMatch, feeType, isAnnualBilling, isMultiPetLinked, petPlans, transactionFee, updateAppState]
    );

    const updateCoverageSettings = useCallback(
        async (newCoverageSettings: CoverageSettings | null) => {
            if (!newCoverageSettings) return;
            setCustomCoverageSettings({ name: `custom`, config: newCoverageSettings });

            updatePricing(newCoverageSettings);

            const { policies } = getValues();
            if (!!policies?.length) {
                if (isMultiPetLinked) {
                    policies.forEach((policy, index) => {
                        setValue(`policies.${index}.coverageSettings`, newCoverageSettings);
                    });
                } else {
                    setValue(`policies.${activePetIndex}.coverageSettings`, newCoverageSettings);
                }
            }

            const allValues = getValues();
            try {
                await updateQuote?.mutateAsync(allValues);
            } catch (error) {
                const hasPcodeError = ErrorUtils.hasSpotError(error, PRIORITY_CODE_DEPRECATED);

                if (hasPcodeError) {
                    let retryData = { ...allValues, affiliateCode: "SPOT", discountCode: "SPOT" };

                    if (PublicConfig.ENVIRONMENT === "development" && mockAPIError) {
                        const updatedExtra = { ...retryData.extra, sim: "" };
                        retryData = { ...retryData, extra: updatedExtra };
                    }

                    await updateQuote?.mutateAsync(retryData);
                }
            }
        },
        [activePetIndex, getValues, isMultiPetLinked, setCustomCoverageSettings, setValue, updatePricing, updateQuote, mockAPIError]
    );

    const handleCustomAmounts = (change: { setting: "annualLimit" | "reimbursementRate" | "annualDeductible"; value: string }) => {
        if (!activePetCoverage || !activePetAllPlans) {
            console.error("Active pet or plans data is undefined.");
            return;
        }

        const updatedAmounts = {
            ...activePetCoverage.config?.amounts,
            [change.setting]: Number(change.value)
        };

        const updatedCoverageSettings: CoverageSettings = {
            ...activePetCoverage.config,
            amounts: updatedAmounts
        };

        const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, updatedCoverageSettings, coverageValuesToMatch);

        if (matchingPlan) {
            const newCoverageSettings = CoverageUtils.getCoverageSettingsFromPlanData(
                matchingPlan,
                wellnessValue === "None" ? undefined : (wellnessValue as "wellness-gold" | "wellness-platinum")
            );
            updateCoverageSettings(newCoverageSettings);
        } else {
            console.error("No matching plan found for the new coverage settings.");
        }
    };

    const handleCoverageLevelChange = (planLevel: "accidentOnly" | "accidentAndIllness") => {
        let newCoverages = [...(activePet?.coverageSettings?.coverages || [])];

        // Adjust coverage based on the coverageLevel change
        const hasIllnessCoverage = newCoverages.some(c => c.type === "illness");
        if (planLevel === "accidentAndIllness" && !hasIllnessCoverage) {
            // Push "illness" coverage to newCoverages with a temporary filler ID
            newCoverages.push({ type: "illness", id: "tempId", name: "Illness Coverage" });
        } else if (planLevel === "accidentOnly" && hasIllnessCoverage) {
            // Remove "illness" coverage if switching back to accidentOnly
            newCoverages = newCoverages.filter(c => c.type !== "illness");
        }

        const updatedCoverageSettings: CoverageSettings = {
            ...activePet?.coverageSettings,
            coverages: newCoverages
        };

        const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, updatedCoverageSettings, coverageValuesToMatch);

        if (matchingPlan) {
            const newCoverageSettings = CoverageUtils.getCoverageSettingsFromPlanData(
                matchingPlan,
                wellnessValue === "None" ? undefined : (wellnessValue as "wellness-gold" | "wellness-platinum")
            );
            updateCoverageSettings(newCoverageSettings);
        } else {
            console.error("No matching plan found for the new coverage settings.");
        }
    };

    const handlePreventiveChange = (wellnessType: string) => {
        // When wellnessType is "None", filter out all wellness coverages, otherwise construct new wellness coverage settings
        const isRemovingWellness = wellnessType === "None";

        // Clone existing coverages, excluding any existing wellness coverage if "None" or updating to the new selection
        const newCoverages = (activePet?.coverageSettings?.coverages || []).filter(coverage => !coverage.type?.includes("wellness"));

        if (!isRemovingWellness && (wellnessType === "wellness-gold" || wellnessType === "wellness-platinum")) {
            const newCoverageSettings: CoverageSettings = {
                ...activePet?.coverageSettings,
                coverages: [{ type: wellnessType }]
            };

            const matchingPlan = CoverageUtils.getPlanDataFromCoverageSettings(activePetAllPlans, newCoverageSettings, coverageValuesToMatch);
            if (matchingPlan) {
                const wellnessRider = CoverageUtils.getRiderFromCoverageType(wellnessType, matchingPlan.riders);
                if (wellnessRider && wellnessRider.id) {
                    // Add the matched wellness coverage with dynamically found id
                    newCoverages.push({ type: wellnessType, id: wellnessRider.id, preventivePrice: wellnessRider.basePrice?.value });
                } else {
                    console.error("No matching wellness rider found for selected plan.");
                }
            } else {
                console.error("No matching plan found for updated wellness coverage settings.");
            }
        }

        // Proceed to update regardless of whether a new wellness plan was added or all wellness coverages were removed
        const updatedCoverageSettings = { ...activePetCoverage?.config, coverages: newCoverages };
        updateCoverageSettings(updatedCoverageSettings);
    };

    const handleActivePetChange = (value: string) => {
        const currentPolicies = getValues().policies ?? [];
        const newActivePetIndex = currentPolicies.findIndex(pet => pet.id === value);

        if (newActivePetIndex === -1) {
            console.warn("Pet not found in current policies");
            return;
        }

        setActivePetIndex(newActivePetIndex);
        const selectedPet = currentPolicies[newActivePetIndex];
        if (selectedPet && selectedPet.coverageSettings) {
            const { coverageSettings } = selectedPet;
            if (coverageSettings.amounts?.annualLimit) {
                setAnnualLimitValue(coverageSettings.amounts.annualLimit.toString());
            }
            if (coverageSettings.amounts?.annualDeductible) {
                setAnnualDeductibleValue(coverageSettings.amounts.annualDeductible.toString());
            }
            if (coverageSettings.amounts?.reimbursementRate) {
                setReimbursementValue(coverageSettings.amounts.reimbursementRate.toString());
            }
            if (coverageSettings.coverages) {
                setCoverageLevel(coverageSettings.coverages.some(coverage => coverage.type === "illness") ? "accidentAndIllness" : "accidentOnly");
                setWellnessValue(getWellnessLevel(coverageSettings));
            }
            setCustomCoverageSettings({ name: "custom", config: coverageSettings });
        }
    };

    useEffect(() => {
        // Initialization
        if (!isQuoteUpdating && !isPetPlansUpdating && allPetsHaveFetchedPlans && !initialRef.current && !!activePet?.coverageSettings) {
            const allPetPlansHaveSameOptions = CoverageUtils.allPetPlansHaveSameOptions(petPlans);
            const isLinked = allPetsHaveSameCoverages && allPetPlansHaveSameOptions;
            updateAppState({ isMultiPetLinked: isLinked });

            // If all pets do not have the same coverage options available, hide the "Apply to all pets" feature
            updateAppState({ isApplyAllHidden: !allPetPlansHaveSameOptions });
            initialRef.current = true;
        }
    }, [activePet?.coverageSettings, allPetsHaveFetchedPlans, allPetsHaveSameCoverages, isPetPlansUpdating, isQuoteUpdating, petPlans, updateAppState]);

    useEffect(() => {
        // If a user previously unchecked Apply to all and they have pets with different coverages, apply the active pet's coverage settings to all pets
        if (
            !isQuoteUpdating &&
            !isPetPlansUpdating &&
            allPetsHaveFetchedPlans &&
            initialRef.current &&
            isMultiPetLinked &&
            !allPetsHaveSameCoverages &&
            !!activePet?.coverageSettings
        ) {
            updateCoverageSettings(activePet.coverageSettings);
        }
    }, [activePet?.coverageSettings, allPetsHaveFetchedPlans, allPetsHaveSameCoverages, isMultiPetLinked, isPetPlansUpdating, isQuoteUpdating, updateCoverageSettings]);

    useEffect(() => {
        if (!customCoverageSettings && !!activePet?.coverageSettings) {
            setCustomCoverageSettings({ name: "custom", config: activePet.coverageSettings });
        }
    }, [activePet?.coverageSettings, customCoverageSettings]);

    useEffect(() => {
        if (!!policies && policies.length === 1 && !isPetPlansUpdating && !isQuoteUpdating) {
            updateAppState({ isApplyAllHidden: true });
        }
        if (!!policies && policies?.length > 1 && !isPetPlansUpdating && !isQuoteUpdating) {
            const allPetPlansHaveSameOptions = CoverageUtils.allPetPlansHaveSameOptions(petPlans);
            if (!allPetPlansHaveSameOptions) {
                updateAppState({ isApplyAllHidden: true, isMultiPetLinked: false });
            } else {
                updateAppState({ isApplyAllHidden: false });
            }
        }
    }, [isPetPlansUpdating, isQuoteUpdating, petPlans, policies, updateAppState]);

    const shouldShowLoader = !coveragePresets.length || !activePet || !underwriter || isPetPlansUpdating;

    useEffect(() => {
        if (shouldShowLoader) {
            updateAppState({ isFormLoaderVisible: true });
        } else {
            updateAppState({ isFormLoaderVisible: false });
        }
    }, [activePet, coveragePresets.length, isFormLoaderVisible, isPetPlansUpdating, shouldShowLoader, underwriter, updateAppState]);

    const renderCoverageTypes = (props: { showLabel?: boolean } = {}) => {
        if (!coverageTypes || coverageTypes.length < 1) return null;
        const { showLabel } = props;
        return (
            <div className="flex flex-col gap-5">
                <FormField>
                    {showLabel && <span className="mb-2 text-sm font-semibold text-content-primary">Coverage</span>}
                    <RadioButtonGroup
                        initialValue={coverageLevel}
                        options={coverageTypes}
                        onValueChange={value => {
                            setCoverageLevel(value);
                            handleCoverageLevelChange(value as "accidentOnly" | "accidentAndIllness");
                        }}
                        className="whitespace-nowrap"
                    />
                </FormField>
            </div>
        );
    };

    const renderIconGrid = () => {
        if (!activePetCoverage || !activePet || !underwriter) return null;
        // todo: Customization slot changes?
        const iconGridWrapperClass = coverageShowTwoColLayout
            ? "grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 w-full gap-x-2 gap-y-4"
            : "grid grid-cols-2 sm:grid-cols-3 md:flex md:flex-wrap md:justify-center gap-3";
        const iconGridItemClass = coverageShowTwoColLayout ? "flex-col text-center" : "flex-col text-center md:w-[160px] xl:w-[150px]";
        const iconsPerBreakpoint = {
            XS: 8,
            SM: 9,
            MD: 12,
            LG: 12,
            XL: 15
        };
        return (
            <CustomizationSlot
                type="icon-grid"
                data={quote}
                formId={formId}
                formStepId={UsQuoteFormSteps.COVERAGE.id}
                fallback={
                    <CoverageIconGrid
                        title={coverageShowTwoColLayout ? "Helps cover eligible claims for:" : ""}
                        titleModal={coverageShowTwoColLayout}
                        coverageInfo={activePetCoverage.config}
                        species={activePet?.species}
                        underwriter={underwriter}
                        samplePolicyUrl={samplePolicyUrl}
                        modalContent={modalContent}
                        wrapperClass={coverageShowTwoColLayout ? "border-none p-0" : ""}
                        iconGridProps={{
                            overrideScreenCheck: coverageShowTwoColLayout ? true : false,
                            wrapperClass: iconGridWrapperClass,
                            itemClass: iconGridItemClass
                        }}
                        iconsPerBreakpoint={coverageShowTwoColLayout ? iconsPerBreakpoint : undefined}
                    />
                }
                priorityCode={priorityCode}
            />
        );
    };

    const renderCoverageFields = () => {
        const wrapperClass = coverageShowTwoColLayout ? "flex flex-col" : "grid lg:grid-cols-3";
        return (
            <div className={cn("gap-3", wrapperClass)}>
                {!!annualLimits && annualLimits.length > 0 && (
                    <FormField>
                        <DynamicOptionsField
                            id="coverage-annual-limit"
                            options={annualLimits}
                            label="Annual Limit"
                            initialValue={annualLimitValue}
                            constrainWidthToTrigger={true}
                            onValueChange={value => {
                                setAnnualLimitValue(value);
                                handleCustomAmounts({ setting: "annualLimit", value });
                            }}
                        />
                    </FormField>
                )}
                {!!reimbursementRates && reimbursementRates.length > 0 && (
                    <FormField>
                        <DynamicOptionsField
                            id="coverage-reimbursement-rate"
                            options={reimbursementRates}
                            label="Reimbursement Rate"
                            initialValue={reimbursementValue}
                            onValueChange={value => {
                                setReimbursementValue(value);
                                handleCustomAmounts({ setting: "reimbursementRate", value });
                            }}
                        />
                    </FormField>
                )}
                {!!annualDeductibles && annualDeductibles.length > 0 && (
                    <FormField>
                        <DynamicOptionsField
                            id="coverage-annual-deductible"
                            label="Annual Deductible"
                            options={annualDeductibles}
                            initialValue={annualDeductibleValue}
                            onValueChange={value => {
                                setAnnualDeductibleValue(value);
                                handleCustomAmounts({ setting: "annualDeductible", value });
                            }}
                        />
                    </FormField>
                )}
            </div>
        );
    };

    return (
        <CoverageEditorProvider editorConfig={props.editorConfig}>
            {!shouldShowLoader && (
                <>
                    {multiPetVariant === "default" ? (
                        <>
                            <AnimatePresence initial={false} mode="sync">
                                {["xs", "sm", "md"].includes(breakpoint ?? "") && policyPets?.length > 1 && !isMultiPetLinked && (
                                    <motion.div
                                        key={`outer-is-linked-${isMultiPetLinked}`}
                                        initial={{ height: 0 }}
                                        animate={{ height: `auto` }}
                                        exit={{ height: 0, transition: { delay: 0.15 } }}
                                        transition={{ duration: 0.15 }}
                                    >
                                        <motion.div
                                            key={`inner-is-linked-${isMultiPetLinked}`}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1, transition: { delay: 0.15 } }}
                                            exit={{ opacity: 0 }}
                                            transition={{ duration: 0.15 }}
                                        >
                                            {/* Padding needed to prevent child border clipping caused by overflow-x-auto */}
                                            <div className="flex w-full flex-col items-start gap-3 overflow-x-auto px-[2px] py-1 sm:flex-row sm:items-center">
                                                <RadioButtonGroup
                                                    initialValue={policyPets[activePetIndex]?.value as string}
                                                    onValueChange={value => handleActivePetChange(value)}
                                                    options={policyPets.map(pet => ({ ...pet, label: UIUtils.truncateString(pet.label, MAX_PET_NAME_LABEL) }))}
                                                    className="h-11"
                                                    disabled={isQuoteUpdating}
                                                />
                                            </div>
                                        </motion.div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <div className="flex flex-col">
                                <div className="grid grid-cols-2">
                                    <div className="col-span-1 flex flex-col gap-1 md:flex-row md:items-center md:gap-4">
                                        <Heading level="h1" className="text-lg font-bold">
                                            {title}
                                        </Heading>
                                        {isApplyAllHidden ? null : <MultipetToggle policies={props?.value?.policies} />}
                                        <AnimatePresence initial={false} mode="sync">
                                            {!["xs", "sm", "md"].includes(breakpoint ?? "") && policyPets?.length > 1 && !isMultiPetLinked && (
                                                <motion.div
                                                    key={`outer-is-linked-${isMultiPetLinked}`}
                                                    initial={{ height: 0 }}
                                                    animate={{ height: `auto` }}
                                                    exit={{ height: 0, transition: { delay: 0.15 } }}
                                                    transition={{ duration: 0.15 }}
                                                >
                                                    <motion.div
                                                        key={`inner-is-linked-${isMultiPetLinked}`}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1, transition: { delay: 0.15 } }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.15 }}
                                                    >
                                                        {/* Padding needed to prevent child border clipping caused by overflow-x-auto */}
                                                        <div className="flex w-full flex-col items-start gap-3 overflow-x-auto px-[2px] py-1 sm:flex-row sm:items-center">
                                                            <RadioButtonGroup
                                                                initialValue={policyPets[activePetIndex]?.value as string}
                                                                onValueChange={value => handleActivePetChange(value)}
                                                                options={policyPets.map(pet => ({ ...pet, label: UIUtils.truncateString(pet.label, MAX_PET_NAME_LABEL) }))}
                                                                className="h-11"
                                                                disabled={isQuoteUpdating}
                                                            />
                                                        </div>
                                                    </motion.div>
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </div>
                                    <div className="col-span-1 flex flex-col justify-end gap-1 md:flex-row md:items-center md:gap-4">
                                        {isMultiPetLinked ? (
                                            <PriceInfo
                                                variant="all-pets-v2"
                                                totalPrice={priceInfoData.roundedPrice}
                                                discountsAmount={priceInfoData.discountsAmount}
                                                policiesCount={quote?.policies?.length ?? 0}
                                            />
                                        ) : (
                                            <PriceInfo
                                                variant="single-pet-v2"
                                                petId={activePet?.id}
                                                totalPrice={priceInfoData.roundedPrice}
                                                discountsAmount={priceInfoData.discountsAmount}
                                                policiesCount={quote?.policies?.length ?? 0}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex items-center justify-between">
                                <Heading level="h1" className="text-lg font-bold">
                                    {title}
                                </Heading>
                                {isApplyAllHidden ? null : <MultipetToggle policies={policies} />}
                            </div>

                            <MultiPetSelect
                                variant={multiPetVariant as "card" | "hybrid" | "dropdown"}
                                policies={policies ?? []}
                                activePet={activePet}
                                setActivePetIndex={setActivePetIndex}
                                policyPets={policyPets}
                                handleActivePetChange={handleActivePetChange}
                                isMultiPetLinked={isMultiPetLinked}
                                isApplyAllHidden={isApplyAllHidden}
                                isQuoteUpdating={isQuoteUpdating}
                                priceInfoData={priceInfoData}
                                updateCoverageSettings={updateCoverageSettings}
                                policiesCount={quote?.policies?.length ?? 0}
                                petModalConfig={petModalConfig}
                            />
                        </>
                    )}

                    <div className="flex flex-col">
                        {coverageShowTwoColLayout ? (
                            <div className="mt-3 grid rounded-3xl border border-stroke-primary shadow-md lg:grid-cols-3">
                                <div className="flex flex-col gap-3 border-stroke-primary p-4 max-lg:border-b sm:px-6 md:pt-6 lg:border-r lg:px-4 lg:pt-4 xl:gap-4 xl:p-6">
                                    {renderCoverageTypes({ showLabel: true })}
                                    {renderCoverageFields()}
                                    <ReimbursementTerms termsInModal={termsInModal} />
                                </div>
                                <div className="px-4 pb-4 pt-6 lg:col-span-2 lg:p-4 xl:p-6 xl:pb-4">{renderIconGrid()}</div>
                            </div>
                        ) : (
                            <>
                                <div className="mt-3 flex flex-col gap-4">
                                    {renderCoverageTypes()}
                                    {renderIconGrid()}
                                </div>
                                <div className="mt-7 flex flex-col gap-4">
                                    {renderCoverageFields()}
                                    <ReimbursementTerms termsInModal={termsInModal} />
                                </div>
                            </>
                        )}

                        {/* </React.Fragment> */}

                        {showTakeoverProvision && <TakeoverProvision />}

                        <CustomizationSlot
                            type="above-preventive"
                            data={quote}
                            formId={formId}
                            formStepId={UsQuoteFormSteps.COVERAGE.id}
                            formData={props.value?.extra?.formData}
                            updateData={data => builderUtils.updateQuoteExtraData({ quote: props.value, newDataArray: data, updateQuote: () => updateQuote })}
                            priorityCode={priorityCode}
                        />

                        {!!activePetCoverage && underwriter && !!preventiveCareOptions && preventiveCareOptions.length > 1 && (
                            <div className="mt-7 flex flex-col gap-4 lg:mt-10">
                                <div className="flex flex-col gap-2 text-center">
                                    <Heading level="h2" className="text-lg font-bold">
                                        Add {preventiveConfig.labels.basic} or {preventiveConfig.labels.advanced} Preventive Care Coverage
                                    </Heading>
                                    <p className="text-sm text-content-secondary">
                                        Get fixed reimbursement amounts every year for certain vaccines, wellness exams, and more, with these optional wellness packages.
                                    </p>
                                </div>
                                <PreventiveCards
                                    pet={activePet as Pet}
                                    preventiveOptions={preventiveCareOptions}
                                    preventiveConfig={preventiveConfig}
                                    underwriter={underwriter}
                                    wellnessLevel={wellnessValue}
                                    handleChange={value => {
                                        setWellnessValue(value);
                                        handlePreventiveChange(value);
                                    }}
                                />
                                {!!coverageTypes && coverageTypes.length === 1 && <AdditionalPlans underwriter={underwriter} />}
                                {!!exclusions && <>{exclusions}</>}
                                {!!customizationSlot && <>{customizationSlot}</>}
                            </div>
                        )}
                    </div>
                </>
            )}
        </CoverageEditorProvider>
    );
}

const CoverageEditorContext = createContext<CustomCoverageProps["editorConfig"] | undefined>(undefined);

export const useCoverageEditorContext = () => {
    const context = useContext(CoverageEditorContext);
    if (context === undefined) {
        throw new Error("useCoverageEditorContext must be used within a CoverageEditorProvider");
    }
    return context;
};

interface CoverageEditorProviderProps {
    children: ReactNode;
    editorConfig: CustomCoverageProps["editorConfig"];
}

const CoverageEditorProvider: React.FC<CoverageEditorProviderProps> = ({ children, editorConfig }) => {
    return <CoverageEditorContext.Provider value={editorConfig}>{children}</CoverageEditorContext.Provider>;
};
